export const MainPortalRolesType = {
  superAdmin: 'Super Admin',
  admin: 'Admin',
  clinician: 'Clinician',
  user: 'user',
  patient: 'Patient',
  carer: 'Carer',
  family: 'Family',
  teacher: 'Teacher',
  mAdmin: 'MAdmin',
};
