import React, { createContext, useContext, useEffect, useReducer } from 'react';

import {
  AuthAction,
  AuthState,
  authReducer,
  initialState,
} from '../reducers/AuthReducer';
import jwtDecode from 'jwt-decode';
import { modules } from '../scopes/ModuelConst';
import { appUrls } from '../../services/AppURLs';
import { MainPortalRolesType } from '../type/UserRouterRoles';
import { useAppDispatch } from '../../app/hooks';
import { clearState, findOrInsertUser } from '../../features/Auth/AuthSlice';

// a authDispatch can only do action types set in reducer
type AuthContextProps = {
  state: AuthState;
  authDispatch: React.Dispatch<AuthAction>;
  checkAuth?: any;
  loginCheck: (token: string | null) => Promise<void>;
};

const AuthContext = createContext<AuthContextProps>({
  state: initialState,
  authDispatch: () => initialState,
  loginCheck: async () => {},
});

export function AuthContextProvider(props: React.PropsWithChildren<{}>) {
  const [state, authDispatch] = useReducer(authReducer, initialState);
  const dispatch = useAppDispatch();

  const loginCheck = async (token: string | null) => {
    if (!token) return;
    if (token) {
      localStorage.clear();
      dispatch(findOrInsertUser(token))
        .unwrap()
        .then((data) => {
          const Decode: any = jwtDecode(localStorage.getItem('token') || '');
          const scope = Decode?.scopePermissions || [];
          const userInfo: any = jwtDecode(Decode.accessToken);
          // console.log('data', Decode, userInfo);

          authDispatch({ type: 'LOAD_USER', user: userInfo });
          dispatch(clearState());
          authDispatch({ type: 'LOGIN' });
          authDispatch({
            type: 'MAINPORTAL_SCOPE',
            mainScopes: Decode.maiaScope,
          });
          authDispatch({ type: 'USER_SCOPE', scopes: scope });
          checkAuth()
        })
        .catch((e) => {
          console.log('error---', e);
          window.location.href = '/no-access';
        });
    }
  };

  async function checkAuth() {
    const accessToken = await localStorage.getItem('token');
    
    const global = JSON.parse(localStorage.getItem('globalsettings') || '{}');
    
    if (!accessToken) return;
    if (accessToken) {
      try {
        const decoded: any = jwtDecode(accessToken);
        const userInfo: any = jwtDecode(decoded.accessToken);

        // console.log('decoded', decoded);
        // console.log('userInfo', userInfo);

        const decodedPayload = decoded?.maiaScope || [];

        if (userInfo) {
          authDispatch({ type: 'LOGIN' });
          const responseModified = { ...userInfo };

          responseModified.role = responseModified.role;
          if (
            responseModified.role === MainPortalRolesType.clinician ||
            responseModified.role === MainPortalRolesType.superAdmin ||
            responseModified.role === MainPortalRolesType.patient ||
            responseModified.role === MainPortalRolesType.mAdmin
          ) {
            authDispatch({ type: 'LOAD_USER', user: userInfo });
            authDispatch({
              type: 'MAINPORTAL_SCOPE',
              mainScopes: decoded.maiaScope,
            });
            authDispatch({
              type: 'SET_GLOBAL_SETTINGS',
              globalSettings: {
                modules: convertModuleSettings(
                  global.modules
                ),
                theme: decodedPayload.theme || userInfo?.result?.themes,
                siteConfigs:
                  decoded.globalSettings.siteConfigs,
              },
            });
          } else {
            authDispatch({ type: 'LOAD_USER', user: responseModified });
          }
        } else {
        }
      } catch (error) {
        console.log('error', error);
      }
    }
  }

  // Module conversion to object from array
  function convertModuleSettings(moduleVal: any) {
    const modulesObj: any = {};
    modulesObj.card_connect = moduleVal.card_connect || false;
    modulesObj.card_administration = moduleVal.card_administration || false;
    modulesObj.card_myHealth = moduleVal.card_myHealth || false;
    modulesObj.card_tracker = moduleVal.card_tracker || false;
    modulesObj.card_resource = moduleVal.card_resource || false;
    modulesObj.card_fitbit = moduleVal.card_fitbit || false;
    modulesObj.card_oauth = moduleVal.card_oauth || false;
    modulesObj.card_resource = moduleVal.card_resource || false;
    modulesObj.card_referral = moduleVal.card_referral || false;
    modulesObj.card_accessibility = moduleVal.card_accessibility || false;
    modulesObj.connect = { connect: moduleVal.card_connect || false };
    modulesObj.myHealth = {};
    modulesObj.accessibility = {};
    modulesObj.tracker = {};
    modulesObj.fitbit = {};
    modulesObj.oauth = {};
    modulesObj.referral = {};
    modulesObj.resource = {};
    modulesObj.portalAdministration = {};

    modules.myHealth.map((item) => {
      const modCheck =
        moduleVal &&
        moduleVal.myHealth &&
        moduleVal.myHealth.find((mod: any) => mod.name === item.name);
      modulesObj.myHealth[item.name] = (modCheck && modCheck?.active) || false;
    });
    modules.oauth.map((item) => {
      const modCheck =
        moduleVal &&
        moduleVal.oauth &&
        moduleVal.oauth.find((mod: any) => {
          // console.log('mod.name', mod.name, item.name);

          return mod.name === item.name;
        });
      // console.log('modCheck', modCheck);

      modulesObj.oauth[item.name] = (modCheck && modCheck?.active) || false;
    });
    modules.accessibility.map((item) => {
      const modCheck =
        moduleVal &&
        moduleVal.accessibility &&
        moduleVal.accessibility.find((mod: any) => mod.name === item.name);
      modulesObj.accessibility[item.name] =
        (modCheck && modCheck?.active) || false;
    });
    modules.fitbit.map((item) => {
      const modCheck =
        moduleVal &&
        moduleVal.fitbit &&
        moduleVal.fitbit.find((mod: any) => mod.name === item.name);
      modulesObj.fitbit[item.name] = (modCheck && modCheck?.active) || false;
    });
    modules.tracker.map((item) => {
      const modCheck =
        moduleVal &&
        moduleVal.tracker &&
        moduleVal.tracker.find((mod: any) => mod.name === item.name);
      modulesObj.tracker[item.name] = (modCheck && modCheck?.active) || false;
    });

    modules.resource.map((item) => {
      const modCheck =
        moduleVal &&
        moduleVal.resource &&
        moduleVal.resource.find((mod: any) => mod.name === item.name);
      modulesObj.resource[item.name] = (modCheck && modCheck?.active) || false;
    });

    modules.referral.map((item) => {
      const modCheck =
        moduleVal &&
        moduleVal.referral &&
        moduleVal.referral.find((mod: any) => mod.name === item.name);
      modulesObj.referral[item.name] = (modCheck && modCheck?.active) || false;
    });

    modules.portalAdministration.map((item) => {
      const modCheck =
        moduleVal &&
        moduleVal.portalAdministration &&
        moduleVal.portalAdministration.find(
          (mod: any) => mod.name === item.name
        );
      modulesObj.portalAdministration[item.name] =
        (modCheck && modCheck?.active) || false;
    });

    return modulesObj;
  }

  useEffect(() => {
    if (document.referrer !== appUrls.outerDomainUrls.mainParent.home) {
      checkAuth();
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{ state, authDispatch, checkAuth, loginCheck }}
      {...props}
    />
  );
}

export default function useAuthContext() {
  return useContext(AuthContext);
}
